@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;

  min-height: 100vh;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./assets/media/hero.webp);
  /* change hue of background */
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top;
  background-color: rgb(15, 6, 20);
}

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
